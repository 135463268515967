function resizeEvent() {
}

$(document).ready(function() {
	$(".header .trigger").click(function() {
		$("body").toggleClass("active-menu");
	});
	$(".menu .mobile-only > li > a").click(function() {
		$(this).next().toggleClass("active");
		return false;
	});
	resizeEvent();
	$(window).resize(function() {
		resizeEvent();
	});
	$(".one-time").slick({
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		adaptiveHeight: true
	});

	$(document).ready(function() {
		$(".fancybox").fancybox();
	});
});